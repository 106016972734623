import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { observe } from "mobx";

import PreparingReport from "pages/report/PreparingReport";
import { useNavigate, useParams } from "react-router-dom";
import {
  InsightReportProvider,
  useInsightReport
} from "util/hooks/useInsightReport";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import { EnquiryIdContext } from "util/hooks/useEnquiryId";
import { GetReportErrorCodes } from "api/insight-reports";

const PreparingReportContainer = props => {
  const { enquiryStore, diagnosticsStore } = props;
  const [statusColumns] = useState([
    [null, null, null, null],
    [null, null, null, null],
    [null, null, null, null]
  ]);
  const [currentStatusText] = useState("");

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { status: insightsStatus, errorMessage }
  } = useInsightReport();

  useEffect(() => {
    enquiryStore.setCurrentEnquiryId(params.enquiryId);

    const isLoadingInsights =
      insightsStatus === InsightReportStatus.fetching ||
      errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR;

    const dispose = observe(diagnosticsStore, change => {
      if (change.name === "enquiryOverview") {
        if (
          change.newValue &&
          change.newValue.reportReady &&
          !isLoadingInsights
        ) {
          navigate(`/report/${params.enquiryId}`);
        }
      }
    });

    window.scrollTo(0, 0);

    return dispose;
  }, [
    enquiryStore,
    diagnosticsStore,
    navigate,
    params,
    insightsStatus,
    errorMessage
  ]);

  return (
    <PreparingReport
      messages={diagnosticsStore.messages}
      statusColumns={statusColumns}
      currentStatusText={currentStatusText}
      enquiryId={params.enquiryId}
    />
  );
};

const HOC = inject(
  "enquiryStore",
  "diagnosticsStore"
)(observer(PreparingReportContainer));

const Container = () => {
  const { enquiryId } = useParams();

  return (
    <EnquiryIdContext.Provider value={enquiryId}>
      <InsightReportProvider>
        <HOC />
      </InsightReportProvider>
    </EnquiryIdContext.Provider>
  );
};

export default Container;
