import { styled } from "@linaria/react";

import Flag from "components/atoms/Flag";

import { fontSize, family } from "styles/fonts";
import { grey, red } from "styles/colors";

const TopRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CustomFlag = styled(Flag)`
  width: 30px;
  img {
    width: 24px !important;
    height: 18px !important;
    margin: 0;
    margin-top: 1px !important;
    margin-right: 8px !important;
    border-radius: 2px !important;
  }
`;

const CountryField = styled.div`
  display: flex;
  padding-top: 2px;
`;

const CountryName = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const SubTitle = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const SubText = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const NameField = styled.div`
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
  line-height: 22px;
  margin-top: -4px;
`;

const FlagPillsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding-top: 11px;
`;

const AkasAndAgeField = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding-top: 7px;
`;

const Date = styled.span`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  text-transform: capitalize;
`;

const BirthDateField = styled.div`
  color: ${grey.mid};
`;

const Akas = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Age = styled.span`
  color: ${grey.dark};
`;

const RelationshipType = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.md};
  line-height: 20px;
  padding-top: 5px;
`;

const Rule = styled.div`
  width: 100px;
  height: 1px;
  background-color: ${grey.rule};
  margin-top: 4px;
  margin-bottom: 6px;
`;

const SubsidiaryField = styled.div`
  padding-bottom: 4px;
`;

const DeceasedLabel = styled.div`
  padding-top: 7px;
`;

const AdditionalCount = styled.span`
  color: ${grey.mid};
`;

const DividingLine = styled.div`
  height: 1px;
  margin: 10px 0;
  border-top: 1px solid ${grey.rule};
`;

const AssociateRiskCounts = styled.div`
  margin-top: 8px;
`;

const AssociatesWithRiskLabel = styled.p`
  color: ${red.directRisk};
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  margin-bottom: 0;
`;

const S = {
  SubText,
  SubTitle,
  NameField,
  CustomFlag,
  CountryName,
  TopRow,
  FlagPillsContainer,
  CountryField,
  AkasAndAgeField,
  Date,
  BirthDateField,
  Akas,
  Age,
  RelationshipType,
  Rule,
  SubsidiaryField,
  DeceasedLabel,
  AdditionalCount,
  DividingLine,
  AssociateRiskCounts,
  AssociatesWithRiskLabel
};

export default S;
